








import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class InsLogo extends Vue {
  // @Prop({ default: '' }) private src!: string;
}
